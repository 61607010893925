import Flickity from 'flickity'
import throttle from 'lodash/throttle'
import { query, smallerThan } from 'Helpers'

export default class CarouselThumbnails {
  /**
   * creates an instance of CarouselThumbnails
   *
   * @memberof CarouselThumbnails
   */
  constructor () {
    this.$container = query('.js-CarouselThumbnails')
    this.$carouselMain = query('.js-CarouselThumbnails-main')
    this.$navigation = query('.js-CarouselThumbnails-nav')

    // Bind context to event handlers.
    this.onResizeHandler = throttle(this.onResize.bind(this), 500)

    this.initialIndex = 0

    if (!this.$container) {
      return
    }

    this.arrowShape = {
      x0: 10,
      x1: 40,
      y1: 30,
      x2: 40,
      y2: 25,
      x3: 15
    }

    this.mobileConfig = {
      arrowShape: this.arrowShape,
      wrapAround: true,
      pageDots: true,
      prevNextButtons: true,
      draggable: false,
      lazyLoad: true
    }

    this.desktopConfig = {
      arrowShape: this.arrowShape,
      wrapAround: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
      selectedAttraction: 0.2,
      friction: 0.81,
      lazyLoad: true
    }

    this.breakpoint = 'sm'

    this.initFlickity()
    this.initNavigation()
    this.init()
  }

  /**
   * Attaches all events to DOM elements.
   *
   * @memberof Accordion
   */
  init () {
    window.addEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * Initialize flickity
   *
   * @memberof CarouselThumbnails
   */
  initFlickity () {
    if (!this.$carouselMain) {
      return
    }

    this.activeConfig = smallerThan(this.breakpoint) ? this.mobileConfig : this.desktopConfig
    this.activeConfig.initialIndex = this.initialIndex

    this.flickityMain = new Flickity(this.$carouselMain, this.activeConfig)

    this.flickityMain.on('select', () => {
      /**
      *Save initialIndex after each slide change,
      *This will remember the last active slide after resizing and each re-init of Flickity.
      */
      this.initialIndex = this.flickityMain.selectedIndex
      this.setActiveSlide(this.flickityMain.selectedIndex, false)
    })
  }

  /**
   * Initialize the Thumbnailnavigation for the carousel
   *
   * @memberof CarouselThumbnails
   */
  initNavigation () {
    const thumbnails = this.$navigation.querySelectorAll('.js-CarouselThumbnails-thumbnail')

    if (!this.$navigation || thumbnails.length === 0) {
      return
    }

    thumbnails.forEach((el, i) => {
      el.addEventListener('mouseover', () => {
        this.setActiveSlide(i)
      })
    })

    this.setActiveSlide(this.flickityMain.selectedIndex, false)
  }

  setActiveSlide (index, updateFlickity = true) {
    const el = this.$navigation.querySelectorAll('.js-CarouselThumbnails-thumbnail')[index]

    this.$navigation.querySelectorAll('.is-active')
      .forEach(activeElement => activeElement.classList.remove('is-active'))

    el.classList.add('is-active')

    if (updateFlickity) {
      this.flickityMain.select(index, false, true)
    }
  }

  /**
   * Reinitialises Flickity on resize to avoid wrong behaviors.
   * NOTE: this is throttled (see initFlickity method)
   *
   * @returns {Function}
   * @memberof CarouselThumbnails
   */
  onResize () {
    this.destroyFlickity()
    this.initFlickity()
  }

  /**
   * Destroy Flickity instance.
   */
  destroyFlickity () {
    if (!this.flickityMain) {
      return
    }

    this.flickityMain.destroy()
  }

  /**
   * Destroy all component's events.
   *
   * @memberof CarouselThumbnails
   */
  destroy () {
    this.destroyFlickity()
    window.removeEventListener('resize', this.onResizeHandler, false)
  }
}
